<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="mt-0 mb-2">
        {{ $t('developer-tab.api-credentials.title') }}
      </h1>
      <router-link :to="{ name: 'create-api-credentials' }">
        <button class="mx-2 px-3 btn btn-primary btn-sm">
          {{ $t('developer-tab.api-credentials.generate') }}
        </button>
      </router-link>
    </div>
    <data-table
      ref="apiCredentials"
      :data="credentials"
      :fields="translatedFields"
      :isLoading="credentialsIsLoading"
      :pagination="pagination"
    >
      <template slot="actions" slot-scope="props">
        <button
          class="mx-2 px-3 btn btn-secondary btn-sm"
          @click="restoreCredential(props.rowData.id)"
          v-if="props.rowData.isDeleted"
        >
          {{ $t('developer-tab.api-credentials.table.restore') }}
        </button>
        <button
          class="mx-2 px-3 btn btn-primary btn-sm"
          @click="onEditCredential(props.rowData)"
          v-if="!props.rowData.isDeleted"
        >
          {{ $t('developer-tab.api-credentials.table.edit') }}
        </button>
        <button
          class="mx-2 px-3 btn btn-danger btn-sm"
          @click="deleteCredential(props.rowData.id)"
          v-if="!props.rowData.isDeleted"
        >
          {{ $t('developer-tab.api-credentials.table.delete') }}
        </button>
      </template>
    </data-table>

    <modal
      :isOpen="isModalOpen"
      @dismiss="isModalOpen = false"
      :title="$t('developer-tab.api-credentials.edit-form.title')"
    >
      <EditCredentialForm
        :currentCredential="editedCredential"
        @submit="
          fetchCredentials()
          isModalOpen = false
        "
      />
    </modal>
  </div>
</template>

<script>
import Vue from 'vue'
import ApiGatewayCredentialsService from '../../../../services/apiGatewayCredentials.service'
import DataTable from '../../../../elements/DataTable.vue'
import Modal from '../../../../elements/Modal'

import apiId from './cells/apiId.vue'
import services from './cells/services.vue'
import isDeleted from './cells/isDeleted.vue'
import EditCredentialForm from './components/EditCredentialForm.vue'

Vue.component('apiId', apiId)
Vue.component('services', services)
Vue.component('isDeleted', isDeleted)

export default {
  components: { DataTable, Modal, EditCredentialForm },
  data() {
    return {
      credentials: [],
      fields: [
        {
          name: 'title',
          title: 'developer-tab.api-credentials.table.title'
        },
        {
          name: '__component:apiId',
          title: 'developer-tab.api-credentials.table.api-id'
        },
        {
          name: '__component:services',
          title: 'developer-tab.api-credentials.table.services',
          width: '40%'
        },
        {
          name: '__component:isDeleted',
          title: 'developer-tab.api-credentials.table.is-deleted'
        },
        {
          name: '__slot:actions',
          title: 'developer-tab.api-credentials.table.actions'
        }
      ],
      pagination: {
        perPage: 10
      },
      credentialsIsLoading: false,
      isModalOpen: false,
      editedCredential: {
        services: []
      }
    }
  },
  computed: {
    translatedFields() {
      return this.fields.map(field => ({
        ...field,
        title: this.$t(field.title)
      }))
    }
  },
  methods: {
    async fetchCredentials() {
      const credentials = await ApiGatewayCredentialsService.fetchAllCredentials()
      this.credentials = credentials
    },
    onEditCredential(credential) {
      this.editedCredential = { ...credential }
      this.isModalOpen = true
    },
    async deleteCredential(id) {
      try {
        await ApiGatewayCredentialsService.deleteCredential(id)
        this.toast(this.$t('developer-tab.api-credentials.toasts.delete'))
        this.fetchCredentials()
      } catch (error) {
        this.toast(error.response.data.message, { type: 'error' })
      }
    },
    async restoreCredential(id) {
      try {
        await ApiGatewayCredentialsService.restoreCredential(id)
        this.toast(this.$t('developer-tab.api-credentials.toasts.restore'))
        this.fetchCredentials()
      } catch (error) {
        this.toast(error.response.data.message, { type: 'error' })
      }
    }
  },
  mounted() {
    this.fetchCredentials()
  }
}
</script>

<style scoped lang="scss">
.gap-2 {
  gap: 0.5rem;
}
</style>
