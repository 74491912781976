<template>
  <div class="d-flex flex-wrap justify-content-center gap-2 services">
    <b-badge
      variant="primary"
      v-for="service in rowData.services"
      :key="service"
    >
      {{ service }}
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'services',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.gap-2 {
  gap: 0.5rem;
}
</style>
