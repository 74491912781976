<template>
  <form @submit.prevent="saveCredentials">
    <div class="d-flex flex-column gap-2 mb-3">
      <!-- TODO until change of mind -->
      <!-- <Field
        :label="$t('developer-tab.api-credentials.edit-form.form.title.label')"
        :placeholder="
          $t('developer-tab.api-credentials.edit-form.form.title.placeholder')
        "
        type="text"
        class="flex-fill"
        v-model="form.title"
        :error="errors[`title`]"
      /> -->
      <p class="mb-0">
        {{ $t('developer-tab.api-credentials.edit-form.form.services.label') }}
      </p>
      <Select
        :options="servicesOptions"
        v-model="form.services"
        multiple
        :allowEmpty="true"
        :preselectFirst="false"
        :placeholder="
          $t(
            'developer-tab.api-credentials.edit-form.form.services.placeholder'
          )
        "
        :error="errors['services']"
      >
      </Select>
    </div>
    <button class="w-100 btn btn-primary btn-sm" type="submit">
      {{ $t('developer-tab.api-credentials.edit-form.form.update') }}
    </button>
  </form>
</template>

<script>
import { array, object, string } from 'yup'
import ApiGatewayCredentialsService from '../../../../../services/apiGatewayCredentials.service'
import { yupToKV } from '../../../../../utils/yup'

export default {
  components: {},
  computed: {
    editSchema() {
      return object().shape({
        title: string()
          .min(
            2,
            this.$t(
              'developer-tab.api-credentials.edit-form.form.title.minLength',
              { min: 2 }
            )
          )
          .required(
            this.$t(
              'developer-tab.api-credentials.edit-form.form.title.required'
            )
          ),
        services: array()
          .of(
            string()
              .trim()
              .required(
                this.$t(
                  'developer-tab.api-credentials.edit-form.form.service.required'
                )
              )
          )
          .min(
            1,
            this.$t(
              'developer-tab.api-credentials.edit-form.form.services.minLength',
              { min: 1 }
            )
          )
      })
    },
    servicesOptions() {
      return [
        {
          id: 'pbx',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.pbx'
          )
        },
        {
          id: 'web-call',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.web-call'
          )
        },
        {
          id: 'order-confirmation',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.order-confirmation'
          )
        },
        {
          id: 'call-campaign',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.call-campaign'
          )
        },
        {
          id: 'billing',
          name: this.$t(
            'developer-tab.api-credentials.create-form.servicesOptions.billing'
          )
        }
      ]
    }
  },
  props: {
    currentCredential: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        title: '',
        services: []
      },
      errors: {}
    }
  },
  methods: {
    async saveCredentials() {
      try {
        this.errors = {}
        const data = {
          ...this.form,
          services: this.form.services.map(service => service.id)
        }

        await this.editSchema
          .validate(data, { abortEarly: false })
          .catch(err => {
            this.errors = yupToKV(err)
            console.log(this.errors, data)
          })

        if (this.editSchema.isValidSync(data)) {
          const res = await ApiGatewayCredentialsService.updateCredential(
            this.currentCredential.id,
            data
          )
          this.toast(
            this.$t('developer-tab.api-credentials.edit-form.toasts.success')
          )
          this.$emit('submit')
        }
      } catch (error) {
        console.log(error)
        this.toast(error.response.data.message, { type: 'error' })
      } finally {
      }
    }
  },
  watch: {
    currentCredential: {
      handler() {
        this.form = {
          ...this.currentCredential,
          services: this.currentCredential.services.map(service => ({
            ...this.servicesOptions.find(option => option.id === service)
          }))
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.gap-2 {
  gap: 0.5rem;
}
</style>
